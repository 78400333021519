<template>
  <div class="loading-main">
    <div v-if="loadingShow" class="loading-dialog">
        <div class="loading-notice">{{font}}</div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      timer: null,
      timout: null,
      font: "加载中",
      loadingShow: false
    };
  },
  created() {
    let _self = this;
    _self.timout = setTimeout(() => {
      _self.loadingShow = true;
    }, 1000);
    _self.timer = setInterval(() => {
      if (_self.font.length > 5) {
        _self.font = "加载中";
      } else {
        _self.font += ".";
      }
    }, 1000);
  },
  destroyed() {
    this.timer = null;
  }
};
</script>

<style lang="scss" >
@import "./../style/main.scss";
.loading-main {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 30000;
}
.loading-dialog {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.loading-notice {
  width: 50vw;
  height: 50vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //   background-color: red;
  line-height: 50vw;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 5.7vw;
}
</style>
